import { useMemo } from 'react';
import { useQuery } from 'utils/react-query';
import { reduce, omit } from 'lodash';

import { useActiveProfileData } from 'utils/hooks/useAuthenticatedUser';

import { PROFILES_ENDPOINT, COMPANIES, FESTIVALS, ORGANIZATIONS } from 'constants/endpointConstants';
import { AOS_TYPE_ID, ARTIST_TYPE_ID, EDIT_PAGE_TYPE } from 'constants/consts';

import * as API from '../API';

export function useNotificationCounts(entity, entityId) {
  const queryKey = ['GET_NOTIFICATION_COUNTS', entity, entityId];

  const endpoint = useMemo(() => {
    if (!entityId) {
      return null;
    }

    switch (entity) {
      case EDIT_PAGE_TYPE.ARTIST:
        return `${PROFILES_ENDPOINT}/notifications`;
      case EDIT_PAGE_TYPE.ORGANIZATIONS:
        return `${ORGANIZATIONS}/notifications`;
      // TODO: Org clean up
      case EDIT_PAGE_TYPE.FESTIVAL:
        return `${FESTIVALS}/notifications`;
      case EDIT_PAGE_TYPE.COMPANY:
        return `${COMPANIES}/notifications`;
      default:
        return null;
    }
  }, [entity, entityId]);

  return useQuery(queryKey, () => API.getOne(endpoint, entityId).then(response => response?.data), {
    enabled: !!endpoint,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
}

export function useProfileNotificationCounts() {
  const activeProfile = useActiveProfileData();

  const entity = useMemo(() => {
    switch (activeProfile?.profileType?.id) {
      case ARTIST_TYPE_ID:
        return {
          type: EDIT_PAGE_TYPE.ARTIST,
          id: activeProfile?.id,
        };

      case AOS_TYPE_ID: {
        if (activeProfile?.organizations?.length > 0) {
          return {
            type: EDIT_PAGE_TYPE.COMPANY,
            id: activeProfile?.organizations?.[0]?.organization?.id,
          };
        }

        return null;
      }

      default:
        return null;
    }
  }, [activeProfile]);
  const { data } = useNotificationCounts(entity?.type, entity?.id);
  // Removed as we are not using it on V3 TODO: Change this on V4 version
  const profileNotifications = omit(data, ['changeRequest', 'redMask', 'drafts', 'greyMask']);
  const profileNotificationsCount = reduce(profileNotifications, (sum, n) => sum + n, 0);
  const profileGreyMaskCount = data?.greyMask;
  const profileRedMaskCount = data?.redMask;
  const profileDraftProductionsCount = data?.drafts;
  return {
    profileNotifications,
    profileNotificationsCount,
    notificationsProfileType: entity?.type,
    profileGreyMaskCount,
    profileRedMaskCount,
    profileDraftProductionsCount,
  };
}
